.slider-row {
    margin-top: 150px;
}

.section-slider {
    width: 100%;
    position: relative;
    outline: none !important;
    overflow: hidden;
}

.section-slider .flickity-viewport {
    overflow: hidden;
}

.slider-card {
    width: 50%;
    padding: 20px;
    text-decoration: none;

    @include below(768px) {
        width: 100%;
        padding: 0 5px;
    }

    &:hover {
        .slider-card__image {
            transform: scale(1.2);
            transition: transform 2s ease-in-out;
        }
    }
}

.slider-card__inner {
    width: 100%;
    height: 100%;
    color: $white;
    position: relative;
    background: $black;
}

.slider-card__image-holder {
    width: 93%;
    max-width: 570px;
    overflow: hidden;

    @include below(1024px) {
        width: 100%;
        max-width: none;
    }
}

.slider-card__image {
    width: 100%;
    display: block;
    transform: scale(1);
    transition: transform 4s ease-in-out;
}

.slider-card__side-text {
    right: 0;
    top: 30px;
    width: 5%;
    display: block;
    position: absolute;
    -webkit-writing-mode: vertical-lr;
    -moz-writing-mode: vertical-lr;
    -ms-writing-mode: vertical-lr;
    writing-mode: vertical-lr;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        -ms-writing-mode: tb-rl;
        right: 10px;
    }

    @include below(1024px) {
        display: none;
    }
}

.slider-card__title {
    font-size: 36px;
    font-weight: $bold;
    padding: 30px 0 30px 40px;

    @include below(1024px) {
        font-size: 22px;
        text-align: center;
        padding: 30px 40px;
    }
}

.slider-card__side-text-mobile {
    display: none;

    @include below(1024px) {
        font-size: 12px;
        display: block;
    }
}

.button-row {
    position: relative;
    padding: 0 20px;

    @include below(420px) {
        text-align: center;
    }
}

.button__holder {
    &.hide {
        visibility: hidden;
    }
}

.slider-button {
    width: 8px;
    height: 20px;
    background: none;
    border: none;
    display: inline-block;
    transition: opacity .2s ease-in-out;

    &.prev {
        background-repeat: no-repeat;
        background-image: url('../img/slidearrowprev.png');
    }
    &.next {
        background-repeat: no-repeat;
        background-image: url('../img/slidearrownext.png');
    }

    &:focus {
        outline: none;
    }
    &:hover {
        opacity: 0.6;
    }
}


.slider-dots,
.flickity-page-dots {
    padding: 0 20px;
    display: inline-block;

    li {
        width: 13px;
        height: 13px;
        list-style: none;
        background: $white;
        display: inline-block;
        border-radius: 100%;
        margin: 0 5px;
        cursor: pointer;
        border: 2px solid $black;

        &.is-selected {
            background: $black;
            transition: background .5s ease-in-out;
        }

        &:hover {
            background: $black;
            transition: background .2s ease-in-out;
        }
    }
}

.flickity-page-dots {
    width: 100%;
    display: none;
    margin-top: 20px;
    text-align: center;

    @include below(768px) {
        display: block;
    }
}

.slider__btn {
    margin: 50px 0 0 0;
    @include horizontal-align();

    @include below(500px) {
        margin-top: 30px;
    }
}

.slider__title {
    float: none;
    position: relative;

    h3 {
        font-weight: 700;
    }

    @include below(420px) {
        float: none;
        transform: none;
        text-align: center;
    }
}

#project-line {
    width: 100%;
    max-width: 400px;
    position: absolute;
    bottom: -20px;
    height: 25px;

    @include below(500px) {
        width: 100%;
        max-width: 239px;
        position: relative;
        bottom: 0;
        margin: 0 auto;

        svg path {
            left: -50%;
            transform: translateX(50%);
        }
    }
}
