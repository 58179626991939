.mobile-menu {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 998;
    opacity: 0;
    visibility: hidden;
    background: $green;
    transition: all 0.2s ease-in-out;

    .menu-huvudmenu-container {
        margin-left: 5%;
        @include vertical-align();

        @include below(420px) {
            margin-left: 8%;
        }

        .menu-item {
            list-style: none;
        }

        .menu-item a {
            font-size: 42px;
            margin: 20px 0;
            color: $white;
            position: relative;
            display: inline-block;
            font-weight: $bold;
            text-decoration: none;

            @include below(420px) {
                font-size: 26px;
                margin: 15px 0;
            }

            &:after {
                content: '';
                width: 100%;
                height: 3px;
                display: block;
                opacity: 0;
                position: absolute;
                background: $white;
                transform: translateY(7px);
                transition: all .1s ease-in-out;
            }

            &:hover {
                &:after {
                    opacity: 1;
                    transform: translateY(2px);
                }
            }
        }
    }

    &.is-active {
        visibility: visible;
        opacity: 1;
    }
}

.show-item {
    display: block;
}

.mobile-menu__button {
    display: none;

    @include below(1080px) {
        display: block;
    }
}
