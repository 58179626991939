.grid-background {
    left: 0;
    right: 0;
    top: 80px;
    width: 90%;
    height: 100%;
    z-index: -1;
    margin: 0 auto;
    position: fixed;
}

.grid-background__column {
    width: 16.666666666666667%;
    height: 100%;
    display: block;
    float: left;
    border-right: 1px solid rgba($borderGray, 0.5);

    &:first-of-type {
        border-left: 1px solid rgba($borderGray, 0.5);
    }

    @include below(420px) {
        width: 33.33333333333333%;
    }
}
