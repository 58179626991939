.section-centered-text {
    width: 100%;
    margin: 0 auto;
    padding: 30px 0;
    max-width: 840px;
}

.section-centered-text__title {
    line-height: 58px;
    font-weight: $bold;
    text-align: center;

    @include below(420px) {
        font-size: 26px;
        line-height: 42px;
    }
}

.project-line-holder {
    display: inline-block;
    position: relative;
}

#ourprojectslines {
    position: absolute;
    width: 100%;
    height: 10px;
    display: block;
    left: 0;
    top: 22px;
}

#coworkers {
    position: absolute;
    width: 100%;
    height: 10px;
    display: block;
    left: 0;
    top: 22px;
}

#infoline {
    position: absolute;
    width: 100%;
    height: 10px;
    display: block;
    left: 0;
    top: 10px;
}
