.list-big-row {
    width: 59.9%;
    margin: 100px auto;
    position: relative;

    @include below(1600px) {
        width: calc(90% - 2px);
    }
    @include below(768px) {
        margin: 50px auto;
    }
}

.list-big-row__row {
    padding: 50px 0;
    background: $beige;
    border-bottom: 1px solid $borderGray;
    @include clearfix();

    &:nth-child(odd) {
        background: #E8E5E1;
    }

    &:first-of-type {
        border-top: 1px solid $borderGray;
    }
}

.list-big-row__col {
    float: left;
    width: 50%;
    padding: 0 20px;

    img,
    p img {
        max-width: 100%;
        height: auto;
        margin: 10px 0;
    }

    &:first-of-type {
        width: 30%;
        max-width: none;

        @include below(768px) {
            width: 100%;
        }
    }
    &:last-of-type {
        width: 20%;
        float: right;
        max-width: none;
        text-align: right;

        @include below(768px) {
            display: none;
        }
    }

    @include below(768px) {
        width: 100%;
    }
}

.list-big-row__title {
    font-size: 24px;
    font-weight: $bold;
    line-height: 36px;

    @include below(768px) {
        display: inline-block;
    }
    @include below(420px) {
        font-size: 20px;
    }
}

.list-big-row__date-mobile {
    display: none;

    @include below(768px) {
        display: block;
        margin: 5px 0 20px 0;
        font-size: 12px;
    }
}

.hide-news {
    display: none;
    &.is-visible {
        display: block;
    }
}
