.authors {
    width: 90%;
    max-width: 500px;
    margin: 60px auto 130px auto; 
    text-align: center;

    .button {
        margin-top: 120px;
    }
}

.authors__row {
    color: #666666;
    font-size: 14px;
    line-height: 26px;
    display: block;
}
