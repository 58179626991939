.list-cards {
    cursor: pointer;
    @include clearfix();

    .card__inner {
        margin: 20px;
    }
}

.list-card {
    width: 100%;
    display: table;
    margin-bottom: 40px;
    text-decoration: none;

    &:hover {
        .list-card__title {
            text-decoration: underline;
            -webkit-text-decoration-skip: ink;
            -moz-text-decoration-skip: ink;
            -ms-text-decoration-skip: ink;
            text-decoration-skip: ink;
            transition: all .1s ease-in-out;
        }

        .list-card__image {
            transform: scale(1.2);
            transition: transform 2s ease-in-out;
        }
    }

    @include below(420px) {
        margin-bottom: 60px;
    }
}

.list-card__column {
    width: 50%;
    display: table-cell;
    position: relative;
    overflow: hidden;
    padding-left: 3%;
    vertical-align: middle;

    &:first-of-type {
        padding-left: 0;
    }

    @include below(620px) {
        width: 100%;
        display: block;
        padding-left: 0;
        vertical-align: baseline;

        &:first-of-type {
            padding-right: 0px;
            margin-bottom: 25px;
        }
    }
}

.list-card__image {
    z-index: 0;
    width: 100%;
    position: relative;
    transform: scale(1);
    transition: transform 4s ease-in-out;
}

.list-card__title {
    width: 100%;
    color: $black;
    font-size: 44px;
    line-height: 64px;
    font-weight: $bold;
    margin: 0 0 10px 0;
    hyphens: auto;

    @include below(1400px) {
        font-size: 36px;
        line-height: 58px;
    }

    @include below(960px) {
        font-size: 26px;
        line-height: 36px;
        margin: 10px 0 15px 0;
    }
}

.list-card__small-title {
    font-size: 14px;
    line-height: 20px;
    color: $black;

    @include below(420px) {
        font-size: 12px;
        line-height: 16px;
    }
}

.list-card__text {
    color: #666666;
}

.list-card__quote {
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    max-width: 360px;
    padding: 25px 0 0 60px;
    color: #2E2E2E;

    @include below(420px) {
        padding: 25px 0 0 25px;
    }
}
