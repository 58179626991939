.footer {
    width: 100%;
    z-index: 997;
    padding: 80px 80px 50px 80px;
    position: relative;
    background: $green;
    @include clearfix();

    @include below(768px) {
        padding: 50px 20px;
    }
}

.footer__inner {
    margin: 0 auto;
    width: $contentMaxWidth;
    position: relative;
    z-index: 9999;
}

.footer__column {
    width: 50%;
    float: left;
    color: $white;

    @include below(768px) {
        width: 100%;
        float: none;
    }
}

.footer__title {
    position: relative;
}

.footer__text {
    margin-top: 20px;
    max-width: 400px;
    margin-bottom: 30px;
}

.footer__contact-row {
    float: right;
    max-width: 180px;
    text-align: right;

    @include below(768px) {
        width: 100%;
        float: none;
        margin-top: 40px;
        text-align: left;
    }
}

.footer-contact__title {
    font-size: 18px;
    font-weight: $bold;
    line-height: 21px;
    margin-bottom: 10px;
    display: block;
}

.footer-contact__text {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 40px;
    display: block;
    color: $white;

    a {
        color: $white;
        display: inline-block;
        position: relative;
        margin-bottom: 10px;
        text-decoration: none;

        &:after {
            content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: $white;
            display: block;
            position: absolute;
        }

        &:hover {
            opacity: 0.8;
        }
    }
}

.letter-svg {
    left: 145px;
    top: 5px;
    width: 100px;
    position: absolute;
}

#letter {
    width: 100%;
    max-width: 59px;
    top: -53px;
    position: relative;
    left: 41px;
    height: 47px;
}

#letter-lines {
    width: 100%;
    max-width: 39px;
    position: relative;
    height: 23px;
}

.footer__bottom {
    width: 100%;
    margin-top: 100px;
    display: inline-block;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    span:nth-last-child() {
        text-align: right;
    }

    a {
        text-decoration: none;
    }

    @include below(768px) {
        margin-top: 60px;

        span:nth-last-child() {
            text-align: left;
        }
    }
}

.footer__logo {
    margin: 0 16px;
    display: inline-block;
    vertical-align: middle;

    &:first-of-type {
        margin-left: 0;
    }

    @include below(450px) {
        width: 13%;
    }

    @include below(320px) {
        margin: 0 10px;
    }
}

.footer__copyright {
    color: $white;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    float: right;
    margin-left: auto;

    @include below(970px) {
        margin-left: 0;
        margin-top: 40px;
    }

    @include below(768px) {
        float: left;
        margin-top: 30px;
    }
}
