.flex-text {
    width: 100%;
    max-width: 620px;
    margin: 0 auto;

    p {
        margin: 20px 0;
        color: #666666;
    }
}
