.info-row {
    width: 100%;
    margin: 0 0 100px 0;
    padding: 100px 80px;
    position: relative;
    background: $green;

    @include below(768px) {
        padding: 100px 20px;
    }
    @include below(500px) {
        padding: 40px 20px;
    }
}

.info-row__inner {
    margin: 0 auto;
    display: table;
    z-index: 2;
    position: relative;
}

.info-row__column {
    width: 70%;
    float: left;

    @include below(1024px) {
        width: 100%;
        text-align: center;
    }

    &:last-of-type {
        width: 30%;
        float: right;
        text-align: right;

        @include below(1024px) {
            width: 100%;
            text-align: center;
            float: none;
        }
    }

    p {
        color: $white;
        font-size: 30px;
        line-height: 46px;
        font-weight: $bold;

        @include below(768px) {
            font-size: 24px;
            line-height: 36px;
        }
        @include below(500px) {
            font-size: 18px;
            line-height: 30px;
        }
    }
}

.info-row__button {
    margin-top: 15px;

    @include below(1024px) {
        margin-top: 40px;
    }
    @include below(500px) {
        font-size: 14px;
    }
}
