.text-title-left {
    margin: 130px 0;
    @include clearfix();

    @include below(768px) {
        margin: 100px 0;
    }
}

.text-title-left__title {
    width: 30%;
    float: left;
    padding-right: 5%;
    line-height: 46px;
    font-weight: $bold;
    word-wrap: break-word;

    @include below(768px) {
        width: 100%;
        line-height: 36px;
        margin-bottom: 20px;
    }
}

.text-title-left__text {
    width: 70%;
    float: right;

    p {
        margin: 0 0 20px 0;
    }

    @include below(768px) {
        width: 100%;
    }
}

#big-arrow {
    width: 100%;
    max-width: 200px;
    position: relative;
    max-height: 10px;
    margin-left: 20%;
    margin-top: 20px;
    float: left;

    @include below(768px) {
        display: none;
    }
}