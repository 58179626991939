.flex-quote {
    margin: 60px auto;
    max-width: 1000px;
}

.flex-quote__quote {
    color: $black;
    font-weight: $bold;
    font-size: 22px;
    line-height: 36px;
    max-width: 400px;
    display: block;
}
