.accordion {
}

.accordion__col {
    border-bottom: 2px solid $black;

    &:first-of-type {
        border-top: 2px solid $black;
    }
}

.accordion__header {
    width: 100%;
    padding: 30px 30px 30px 0;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    font-size: 18px;
    font-weight: 700;
    transition: padding .2s ease-in-out, background .1s ease-in-out;

    &:hover {
        color: $white;
        padding: 30px;
        background: $black;

        .accordion__btn {
            border: 2px solid $white;

            &:after,
            &:before {
                background: $white;
            }
        }
    }

    &.is-active {
        color: $white;
        padding: 30px;
        background: $black;

        .accordion__btn {
            border: 2px solid $white;
            transform: rotate(-45deg);

            &:after,
            &:before {
                background: $white;
            }
        }
    }

    @include below(420px) {
        font-size: 16px;
        padding: 25px 25px 25px 0;
    }
}

.accordion__text {
    padding: 30px;
    display: none;
    color: #666666;

    strong {
        color: #2E2E2E;
    }

    ul {
        padding-left: 30px;
    }

    li {
        line-height: 30px;
    }
}

.accordion__btn {
    width: 22px;
    height: 22px;
    float: right;
    display: block;
    position: relative;
    border-radius: 100%;
    border: 2px solid $black;
    transition: all .1s ease-in-out;

    &:before {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        background: $black;
        @include vertical-align(absolute);
    }

    &:after {
        content: '';
        width: 2px;
        height: 100%;
        position: absolute;
        background: $black;
        @include horizontal-align(absolute);
    }

}
