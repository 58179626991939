.sub-page-header {
    margin: 80px auto;
    height: 570px;
    width: $contentMaxWidth;
    max-width: $contentWidth;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../img/aboutusimg.png');
}
