.two-images {
    width: 100%;
    margin: 60px auto;
    max-width: 1280px;
    position: relative;
    @include clearfix();
}

.two-images__image {
    width: 50%;
    float: left;
    padding: 0 20px;
    position: relative;

    img {
        max-width: 100%;
        display: block;
    }

    @include below(420px) {
        width: 100%;
        padding: 0;

        &:first-of-type {
            margin-bottom: 50px;
        }
    }
}

.two-image__text {
    font-size: 14px;
    color: #666666;
    display: block;
    margin: 10px 0 0 0;
}
