.text-button-cta {
    width: 100%;
    text-align: center;
    margin: 50px auto;
    display: inline-block;

    h2 {
        margin: 0 auto;
        max-width: 470px;
        font-weight: $bold;
    }
}
