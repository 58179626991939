// Default content settings.
$contentWidth: 1280px;
$contentPadding: 20px;
$contentMaxWidth: 90%;

// Font families
$mainFont: 'Mikro';

// Font weights.
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

// Colors
$black:#2E2E2E;
$green: #006886;
$white: #fff;
$borderGray: #DCDCDC;
$beige: #F2EFEA;
