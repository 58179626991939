.flex-one-image {
    width: 100%;
    max-width: 840px;
    margin: 80px auto;
    position: relative;

    img {
        max-width: 100%;
        display: block;
    }
}
