@font-face {
    font-family: 'Mikro';
    src: url('../fonts/60c95c8c5c51330196795fa87dc90a64.eot');
    src: url('../fonts/60c95c8c5c51330196795fa87dc90a64.eot?#iefix') format('embedded-opentype'),
        url('../fonts/60c95c8c5c51330196795fa87dc90a64.woff') format('woff'),
        url('../fonts/60c95c8c5c51330196795fa87dc90a64.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mikro';
    src: url('../fonts/4b078e4aca2fe45ed6bd52600a498b7d.eot');
    src: url('../fonts/4b078e4aca2fe45ed6bd52600a498b7d.eot?#iefix') format('embedded-opentype'),
        url('../fonts/4b078e4aca2fe45ed6bd52600a498b7d.woff') format('woff'),
        url('../fonts/4b078e4aca2fe45ed6bd52600a498b7d.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Mikro';
    src: url('../fonts/5149295dd3a12de34e554db548f2ac96.eot');
    src: url('../fonts/5149295dd3a12de34e554db548f2ac96.eot?#iefix') format('embedded-opentype'),
        url('../fonts/5149295dd3a12de34e554db548f2ac96.woff') format('woff'),
        url('../fonts/5149295dd3a12de34e554db548f2ac96.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Mikro';
    src: url('../fonts/711f0b88b3c953566c46526f6d700593.eot');
    src: url('../fonts/711f0b88b3c953566c46526f6d700593.eot?#iefix') format('embedded-opentype'),
        url('../fonts/711f0b88b3c953566c46526f6d700593.woff') format('woff'),
        url('../fonts/711f0b88b3c953566c46526f6d700593.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Mikro';
    src: url('../fonts/82ae40484079bfce87bc43103ad24a2e.eot');
    src: url('../fonts/82ae40484079bfce87bc43103ad24a2e.eot?#iefix') format('embedded-opentype'),
        url('../fonts/82ae40484079bfce87bc43103ad24a2e.woff') format('woff'),
        url('../fonts/82ae40484079bfce87bc43103ad24a2e.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
