.site-header {
    width: 100%;
    height: 90%;
    margin: 0 auto;
    z-index: -2;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    // background-attachment: fixed;
    will-change: transform;

    .site-header__inner {
        @include vertical-align();
    }

    &--small {
        margin: 60px auto;
        min-height: 650px;
        height: auto;

        @include below(768px) {
            min-height: 450px;
        }

        .site-header__inner {
            @include vertical-align(absolute);

            h2 {
                font-size: 64px;
                color: $white;
                letter-spacing: 1px;
                line-height: 84px;
                font-weight: $bold;

                @include below(768px) {
                    font-size: 44px;
                    line-height: 64px;
                }

                @include below(500px) {
                    font-size: 34px;
                    line-height: 54px;
                }
            }
        }

        .site-header__bottom-title {
            @include below(768px) {
                padding: 0 0 25px 5%;

                h2 {
                    font-size: 26px;
                }
            }

            @include below(500px) {
                h2 {
                    font-size: 20px;
                }
            }
        }
    }
}

.site-header__inner {
    margin: 0 auto;
    max-width: 735px;
    text-align: center;
    width: $contentMaxWidth;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10;
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    -webkit-transform: translate3d(0,0,0);
    will-change: transform;
    // @include vertical-align();

    h1 {
        font-size: 64px;
        color: $white;
        letter-spacing: 1px;
        line-height: 84px;
        font-weight: $bold;

        @include below(640px) {
            font-size: 40px;
            line-height: 64px;
        }
        @include below(420px) {
            font-size: 30px;
            letter-spacing: 0;
            line-height: 42px;
        }
    }
}

.site-header__bottom-title {
    position: absolute;
    z-index: 999;
    bottom: 0;
    color: $white;
    max-width: 500px;
    padding: 0 0 50px 5%;

    @include below(640px) {
        font-size: 26px;
    }
    @include below(420px) {
        font-size: 22px;
    }
}

#top-header-svg {
    margin: 0 auto;
    max-width: 400px;
    width: 100%;
    height: 22px;

    @include below(420px) {
        width: 90%;
    }
}
