.section-news-list-holder {
    width: 100%;
    position: relative;
    margin: 100px auto;
}

.section-news-list {
    z-index: 0;
    width: 100%;
    display: flex;
    position: relative;
    padding: 100px 0;
    margin: 0 auto;
    width: $contentMaxWidth;
    max-width: 1320px;
    @include clearfix();

    @include below(1360px) {
        max-width: 1100px;
    }
    @include below(768px) {
        display: block;
        padding: 50px 0;
    }
}

.section-news-list__col {
    flex: 1;
    padding: 20px;
    display: block;
    position: relative;
    width: calc(100% / 3);
    text-decoration: none;
    color: $black;

    @include below(768px) {
        width: 100%;
        padding: 0;
    }

    .button {
        position: absolute;
        bottom: 0;

        @include below(768px) {
            display: none;
        }
    }

    &:hover {

        .section-news-list__title {
            text-decoration: underline;
            -webkit-text-decoration-skip: ink;
            -moz-text-decoration-skip: ink;
            -ms-text-decoration-skip: ink;
            text-decoration-skip: ink;
        }

        .read-more:after {
            right: -20px !important;
        }
    }
}

.static .section-news-list__inner {

    h3 {
        font-weight: $bold;
        position: relative;
    }

    p {
        margin-top: 10px;
        max-width: 300px;
    }
}

#news-line {
    left: -20px;
    bottom: 7px;
    max-width: 150px;
    width: 100%;
    height: 9px;
    position: relative;

    @include below(768px) {
        max-width: 120px;
        left: 0;
        margin-bottom: 20px;
    }
}

#news-arrow {
    top: -20px;
    margin-left: 40%;
    max-width: 130px;
    width: 100%;
    height: 42px;
    position: relative;

    @include below(768px) {
        display: none;
    }
}

.section-news-list__inner {
    width: 100%;
    height: 100%;
    padding: 10px 0 0 0;
}

.section-news-list__news {
    .section-news-list__inner {
        border-top: 2px solid $black;
    }
}

.last-news {
    @include below(768px) {
        display: none;
    }
}

.section-news-list__small-text {
    color: $black;
    font-size: 14px;
    line-height: 20px;

    @include below(420px) {
        font-size: 12px;
        line-height: 16px;
    }
}

.section-news-list__title {
    color: $black;
    font-weight: $bold;
    font-size: 24px;
    line-height: 30px;
    padding: 40px 0 30px 0;

    @include below(768px) {
        font-size: 18px;
    }
    @include below(420px) {
        padding: 40px 0 15px 0;
    }
}

.section-news-list__text {
    margin-bottom: 30px;

    @include below(768px) {
        font-size: 16px;

        p {
            font-size: 16px;
        }
    }
}

.read-more {
    display: block;
    position: absolute;
    bottom: 0;

    &:after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 7px;
        height: 11px;
        right: -15px;
        top: 5px;
        transition: right .1s ease-in-out;
        background-image: url('../img/rightarrow.png');
    }

    @include below(768px) {
        bottom: -30px;
    }
}

.news-btn-mobile {
    display: none;

    @include below(768px) {
        font-size: 14px;
        display: inline-block;
        margin: 40px 0;
        @include horizontal-align();
    }
}
