.single-info {
    width: 100%;
    padding: 50px 0;
    background: $black;
    @include clearfix();

    .hide-on-mobile {
        @include below(768px) {
            display: none;
        }
    }

    .accordion {
        display: table;
        width: 90%;
        margin: 50px auto 0 auto;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        color: #fff;

        .accordion__header {
            padding: 30px 0;
        }
        .accordion__text {
            padding: 0;
            color: $white;
        }
        .accordion__btn {
            border: 2px solid #fff;
            &:before, &:after {
                background: $white;
            }
        }
    }
}

.single-info__inner {
    width: 90%;
    max-width: 1280px;
    margin: 0 auto;
    display: table;
}

.single-info__column {
    width: 65%;
    display: table-cell;
    color: $white;
    vertical-align: middle;

    @include below(768px) {
        width: 100%;
    }

    &:last-of-type {
        width: 25%;
        vertical-align: top;

        @include below(768px) {
            display: none;
        }
    }
}

.single-info__text {
    margin: 0 auto;
    max-width: 620px;

    p {
        font-size: 22px;
        font-weight: $bold;
        line-height: 36px;

        @include below(420px) {
            font-size: 18px;
            line-height: 32px;
        }
    }
}

.single-info__list {
    margin: 0 auto;
}

.single-info__list-column {
    width: 100%;
    display: block;
    margin: 0 0 20px 0;
}

.single-info__list-title {
    font-size: 14px;
    line-height: 20px;
    font-weight: $bold;
}

.single-info__list-text {
    font-size: 14px;
    line-height: 20px;
    color: #CECECE;
    max-width: 290px;
}

.show-on-mobile {
    display: none !important;
    @include below(768px) {
        display: block !important;
    }
}
