.list-jobs {
    z-index: 0;
    margin: 60px auto 100px auto;
    position: relative;
    background: $white;
    width: 59.9%;
    @include clearfix();

    @include below(1560px) {
        width: calc(90% - 2px);
    }
}

.list-job__column {
    width: 100%;
    padding: 50px 20px;
    @include clearfix();
    border-bottom: 1px solid $borderGray;

    &:first-of-type {
        border-top: 1px solid $borderGray;
    }

    &:nth-child(odd) {
        background: #F3F3F3;
    }

    p {
        color: #666666;
        max-width: 620px;
    }
}

.list-job__title {
    font-size: 24px;
    font-weight: $bold;

    @include below(420px) {
        font-size: 18px;
    }
}

.list-job__list-col {
    width: 70%;
    float: left;

    &:first-of-type {
        width: 30%;
    }

    @include below(1024px) {
        width: 100%;
        display: block;

        &:first-of-type {
            width: 30%;
            margin-bottom: 30px;
        }
    }
}

.total-jobs {
    top: 0;
    margin-left: 7px;
    font-size: 14px;
    position: absolute;
}
