.big-image-textblock {
    width: 100%;
    z-index: 0;
    position: relative;
}

.big-image-textblock__inner {
    z-index: 2;
    width: 100%;
    position: relative;
    display: inline-block;
}

.big-image-textblock__textblock {
    width: 90%;
    z-index: 1;
    display: table;
    margin: 0 auto;
    max-width: 1320px;
    position: relative;
    padding: 50px 0 80px 0;
}

.big-image-textblock__image {
    width: 95%;
    z-index: 10;
    margin: 0 auto;
    text-align: center;
    max-width: 1280px;
    position: relative;

    img {
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        max-width: 100%;
        position: relative;
    }

    @include below(768px) {

    }
}

.big-image-textblock__column {
    float: left;
    color: $white;
    padding: 10px 20px;
    width: calc(100% / 3);

    h4 {
        font-size: 18px;
        font-weight: $bold;
        line-height: 30px;
        margin-bottom: 5px;
    }

    @include below(868px) {
        width: 50%;
        float: none;
        vertical-align: top;
        display: inline-block;
    }

    @include below(640px) {
        width: 100%;
    }
}

.big-image-textblock__small-text {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
    display: block;
}

.big-image-textblock__title {
    font-size: 14px;
    line-height: 24px;
    font-weight: $bold;
}

.big-image-textblock__info-col {
    margin-bottom: 30px;

    p {
        font-size: 14px;
        line-height: 24px;
    }
}

.text-image-textblock__background {
    width: 100%;
    height: 70%;
    bottom: 0;
    z-index: 0;
    background: $green;
    position: absolute;

    @include below(868px) {
        height: 80%;
    }
    @include below(768px) {
        height: 90%;
    }
    @include below(460px) {
        height: 96%;
    }
}
