.list-table {
    z-index: 0;
    margin: 60px auto 100px auto;
    position: relative;
    width: calc(90% + 30px);
    max-width: 1320px;
}

.list-table__header {
    width: 90%;
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
}

.list-table__inner {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
}

.list-table__column {
    width: calc(100% / 3);
    padding: 15px;
    margin: 0 0 40px 0;
    transition: all .3s ease-in-out;
    cursor: pointer;

    @include below(1200px) {
        width: 50%;
    }

    @include below(768px) {
        width: 100%;
        margin: 0 0 20px 0;
    }

    &-inner {
        width: 100%;
        position: relative;
        background: #F3F3F3;
        padding: 0 30px 30px 30px;
        transition: all .3s ease-in-out;

        &:hover {
            background: #CDCDCD;
            box-shadow: 0 0 11px rgba(33,33,33,.2);
    
            .list-table__column-header-title {
                text-decoration: underline;
            }

            .list-table__column-header-image {
                box-shadow: 0 0 11px rgba(33,33,33,.2);
            }
        }
    }

    &.is-open {
        width: 66.66%;

        @include below(1200px) {
            width: 100%;
        }

        .list-table__column-inner {
            .list-table__column-footer-info {
                width: calc(100% / 4);

                @include below(620px) {
                    width: 50%;
                }
            }

            .list-table__column-header-images {
                width: 100%;
                height: auto;
                overflow: auto;
                opacity: 1;
            }

            .list-table__column-header-text {
                height: auto;
                overflow: auto;

                &:after {
                    content:"";
                }
            }
            
            .close-icon {
                display: block;
            }
        }   
    }

    &-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        // IE11...
        flex-shrink: 0;

        &-image {
            display: block;
            max-width: 100%;
            position: relative;
            margin: -30px 0 15px 0;
            transition: all .3s ease-in-out;
            align-self: center;

            // IE11...
            flex-shrink: 0;
        }

        &-images {
            width: 0px;
            height: 0px;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            opacity: 0;
            transition: width .3s ease-in-out;

            img {
                width: 49%;
                max-width: 100%;
                display: block;
            }
        }

        &-title {
            font-size: 24px;
            line-height: 29px;
            font-weight: 600;
            color: #000;
            margin: 15px 0 2px 0;

            @include below(1300px) {
                font-size: 22px;
                line-height: 27px;
            }
        }
    
        &-text {
            font-size: 18px;
            line-height: 30px;
            color: #666666;
            height: 90px;
            overflow: hidden;
            position: relative;

            &:after {
                // content: "...";
                // position: absolute;
                // bottom: 0;
                // right: 16px;
            }
        }
    }

    &-footer {
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;

        &-info {
            width: 50%;
            margin: 15px 0 0 0;
            transition: all .3s ease-in-out;
            
            span {
                display: block;
                font-size: 16px;
                line-height: 24px;
            }

            &-title {
                color: #343434;
            }

            &-text {
                color: #666666;
            }
        }
    }

    .close-icon {
        right: 0;
        bottom: 0;
        position: absolute;
        display: none;
    }
}