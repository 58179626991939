.navigationbar {
    width: 100%;
    height: 80px;
    z-index: 999;
    top: 0;
    margin: 0 auto;
    position: fixed;
    border-bottom: 1px solid rgba($borderGray, 0.5);
    transition: background 0.2s ease-in-out;

    &.is-active, &.is-filled {
        background: $white;

        &.beige {
            background: $beige;
        }

        .navigationbar__logo {
            background-image: url('../img/eaab_logo_g.svg');
        }

        .menu-huvudmenu-container {
            .menu-item a {
                color: $black;

                &:after {
                    background: $black;
                }
            }
        }

        .mobile-menu__button {
            color: $black;

            &:after {
                background: $black;
            }
        }

        .navigationbar__contact-btn {
            color: $black;

            &:after {
                background: $black;
            }
        }
    }

    &.is-mobile {
        background: $green;

        .navigationbar__logo {
            background-image: url('../img/eaab_logo_w.svg');
        }
        .mobile-menu__button {
            color: $white;

            &:after {
                background: $white;
            }
        }
    }
}

.navigationbar__inner {
    height: 100%;
    margin: 0 auto;
    padding: 0 $contentPadding;
    border-right: 1px solid rgba($borderGray, 0.5);
    border-left: 1px solid rgba($borderGray, 0.5);
    width: 89.99%; // hmm?
}

.navigationbar__column {
    width: 60%;
    float: left;
    height: 100%;

    &:first-of-type {
        width: 20%;
    }
    &:last-of-type {
        width: 20%;
        float: right;
    }
}

.navigationbar__logo-holder {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 85px;
}

.navigationbar__logo {
    width: 85px;
    height: 26px;
    display: block;
    @include vertical-align();
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../img/eaab_logo_w.svg');
    transition: background-image 0.3s ease-in-out;

    // &:hover {
    //     background-image: url('../img/logo-green.png');
    //     transition: background-image 0.1s ease-in-out;
    // }
}

.back-btn-holder {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    

    svg {
        transform: rotate(180deg);
        margin-right: 15px;
    }
}

.back-btn {
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-weight: $bold;
    text-decoration: none;
    position: relative;

    &:after {
        content: '';
        width: 100%;
        height: 2px;
        display: block;
        opacity: 0;
        position: absolute;
        background: $white;
        transform: translateY(7px);
        transition: all .1s ease-in-out;
    }

    &:hover {
        &:after {
            opacity: 1;
            transform: translateY(2px);
        }
    }
}

.navigationbar .menu {
    .menu-item {
        margin: 0 25px;
        list-style: none;
        display: inline-block;
    }

    a {
        color: $white;
        font-size: 16px;
        position: relative;
        font-weight: $bold;
        text-decoration: none;

        &:after {
            content: '';
            width: 100%;
            height: 2px;
            display: block;
            opacity: 0;
            position: absolute;
            background: $white;
            transform: translateY(7px);
            transition: all .1s ease-in-out;
        }

        &:hover {
            &:after {
                opacity: 1;
                transform: translateY(2px);
            }
        }
    }
}

.navigationbar .menu-huvudmenu-container {
    text-align: center;
    @include vertical-align();
}

.current_page_item a:after {
    opacity: 1 !important;
    transform: translateY(2px) !important;
}

.navigationbar__contact-btn,
.mobile-menu__button {
    float: right;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-weight: $bold;
    text-decoration: none;
    @include vertical-align();

    &:after {
        content: '';
        width: 100%;
        height: 2px;
        display: block;
        opacity: 0;
        position: absolute;
        background: $white;
        transform: translateY(7px);
        transition: all .1s ease-in-out;
    }

    &:hover {
        &:after {
            opacity: 1;
            transform: translateY(2px);
        }
    }
}

.hide-below-desktop {
    @include below(1080px) {
        display: none;
    }
}
