.image-text-cta {
    width: 100%;
    background: $black;
    @include clearfix();
}

.image-text-cta__inner {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include below(830px) {
        flex-direction: column;
    }
}

.image-text-cta__column {
    width: 60%;
    height: 100%;
    position: relative;
    order: 1;

    @include below(830px) {
        width: 100%;
        flex-basis: 0;
    }

    &:first-of-type {
        width: 40%;
        text-align: center;

        @include below(830px) {
            width: 100%;
            order: 2;
        }
    }

    img {
        float: right;
        width: 100%;
    }
}

.image-text-cta__big-title {
    font-weight: $bold;
    margin-bottom: 30px;

    @include below(500px) {
        text-align: center;
    }
}

.image-text-cta__title {
    font-size: 24px;
    color: $white;
    max-width: 350px;
    line-height: 36px;
    margin: 0 auto;
    font-weight: $bold;
}

.image-text-cta__text {
    margin: 0 auto;
    color: #CECECE;
    font-size: 18px;
    line-height: 32px;
    padding: 20px 0 60px 0;
    text-align: center;
    font-weight: $regular;

    @include below(830px) {
        padding-bottom: 40px;
    }
    @include below(420px) {
        font-size: 16px;
        line-height: 26px;
    }
}

.image-text-cta__footer-mobile {
    display: none;
    font-size: 14px;
    color: $white;
    text-align: center;

    @include below(830px) {
        display: block;
        margin-bottom: 5px;
    }
    @include below(420px) {
        font-size: 12px;
        line-height: 16px;
    }
}

.image-text-cta__content {
    padding: 50px 40px 0 40px;

    @include below(830px) {
        padding-bottom: 40px;
    }
}

.image-text-cta__footer {
    width: 100%;
    color: $white;
    line-height: 19px;
    font-size: 16px;
    padding: 20px 30px;
    text-align: right;
    font-weight: $regular;
    display: inline-block;

    a {
        color: $white;
    }

    @include below(830px) {
        display: none;
    }
}

#work-line {
    width: 100%;
    max-width: 239px;
    position: relative;
    top: -15px;
    height: 13px;

    @include below(500px) {
        max-width: 239px;
        position: relative;
        top: 0;
        margin: 0 auto;
    }
}

#cta-block-line {
    width: 100%;
    max-width: 239px;
    position: relative;
    top: -15px;
    height: 13px;

    @include below(500px) {
        max-width: 239px;
        position: relative;
        top: 0;
        margin: 0 auto;
    }
}

.image-text-cta__button {
    margin-top: 50px;
    @include horizontal-align();
}
