body,
html {
    width: 100%;
    height: 100%;
    font-family: $mainFont;
}

.site-wrap {
    height: 100%;
    width: 100%;
    margin: 0 auto;
}

.row {
    margin: 100px auto;
    position: relative;
    width: $contentMaxWidth;
    max-width: $contentWidth;

    @include below(1360px) {
        max-width: 1100px;
    }
    @include below(420px) {
        margin: 50px auto;
    }
}

.flex-section {
    margin: 100px auto;

    @include below(420px) {
        margin: 50px auto;
    }
}

.square-pattern {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
    position: absolute;
    background-image: linear-gradient(white 0px, transparent 0px),
    linear-gradient(90deg, white 0px, transparent 0px),
    linear-gradient(rgba(255,255,255,.1) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255,255,255,.1) 1px, transparent 1px);
    background-size:100px 100px, 100px 100px, 20px 20px, 20px 20px;
    background-position:-2px -2px, -2px -2px, -1px -1px, -1px -1px
}

.testar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .inner-test {
        display: block;
        position: relative;
        width: 100%;
        height: 10%;
        opacity: 0.2;
        border-bottom: 1px solid white;
    }
}

h1 {
    font-size: 64px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 30px;
    font-weight: 700;

    @include below(500px) {
        font-size: 22px;
        line-height: 36px;
    }
}

p {
    font-size: 18px;
    line-height: 26px;

    @include below(420px) {
        font-size: 16px;
    }
}

.button {
    font-size: 20px;
    font-weight: $bold;
    padding: 18px 25px;
    color: $black;
    overflow: hidden;
    display: inline-block;
    background: transparent;
    border: 2px solid $black;
    position: relative;
    z-index: 1;
    cursor: pointer;
    text-decoration: none;
    transition: all .2s ease-in-out;

    &--green {
        color: $white;
        background: $green;
        border: 2px solid $white;
    }

    &--white {
        color: $white;
        background: transparent;
        border: 2px solid $white;
    }

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        background: $black;
        top: -100%;
        left: 0;
        z-index: -1;
        transition: all .1s ease-in-out, top .1s ease-in-out;
    }

    &--green:after {
        background: $white;
    }

    &--white:after {
        background: $white;
    }


    &:hover {
        color: $white;

        &:after {
            animation: buttonHover .4s ease-in-out;
            top: 0;
        }
    }

    @include below(500px) {
        font-size: 14px;
    }
}

.button--green {
    &:hover {
        color: $black !important;
    }
}

.button--white {
    &:hover {
        color: $black !important;
    }
}

@keyframes buttonHover {
    0% {
        top: -100%;
    }
    50% {
        top: 0;
    }
    75% {
        top: -10%;
    }
    100% {
        top: 0;
    }
}

.beige-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -3;
    position: absolute;
    background: $beige;

    &--cover {
        position: fixed;
    }
}


::-webkit-scrollbar
{
  width: 10px;  /* for vertical scrollbars */
  height: 12px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-thumb
{
  background: rgba($green, 1);
}

.scroll__down {
    bottom: 0;
    width: 100%;
    height: 60px;
    z-index: 999;
    color: $white;
    font-size: 16px;
    line-height: 60px;
    font-weight: $bold;
    text-align: center;
    position: fixed;
    cursor: pointer;
    text-decoration: none;
    background: rgba($black, 1);
    transition: all .2s ease-in-out;
    opacity: 1;

    &:hover {
        height: 70px;
        line-height: 70px;
    }

    &.hide {
        opacity: 0;
        visibility: hidden;
    }
}

.arrow-down {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.hide-scrollbar {
    animation: scrollBarHide .3s forwards;
}

@keyframes scrollBar {
    0% {
        transform: translateY(60px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes scrollBarHide {
    0% {
        transform: translateY(0);
    }
    100% {
        visibility: hidden;
        transform: translateY(60px);
    }
}

.text-page-holder {
    margin: 100px auto;
    width: $contentMaxWidth;
    max-width: $contentWidth;
}

.black-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: block;
    background: rgba(#000, 0.3);
    position: absolute;
}

.text-button-block {
    background: $black;
}

.text-button-block__inner {
    width: 100%;
    max-width: 620px;
    margin: 0 auto;
    padding: 90px 20px;
    text-align: center;

    @include below(620px) {
        padding: 60px 15px;
    }

    h2 {
        font-size: 30px;
        line-height: 37px;
        font-weight: 600;
        margin: 0 0 24px 0;
        color: $white;
    }

    p {
        color: $white;
        font-size: 18px;
        line-height: 30px;
        font-weight: 400;
    }

    .button {
        margin-top: 60px;
    }
}

.big-video {
    @include below(768px) {
        margin: 50px auto;
    }

    h3 {
        font-weight: 700;

        @include below(500px) {
            text-align: center;
        }
    }

    #project-line {
        width: 100%;
        max-width: 400px;
        position: absolute;
        bottom: -20px;
        height: 25px;
    
        @include below(500px) {
            position: relative;
            top: 0;
            margin: 0 auto;
            
            svg {
                margin: 0 auto;

                path {
                    left: -27%;
                    transform: translateX(27%);
                }
            }
        }
    }
}

.video-holder {
    position: relative;

    video {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.play-button {
    width: 111px;
    height: 111px;
    border-radius: 100%;
    border: 5px solid #fff;
    display: flex;
    justify-content: center;
    background-color: rgba(#000, 0.6);
    transition: background-color .2s ease-in-out;
    @include align-both(absolute);
    cursor: pointer;
    z-index: 1;

    @include below(768px) {
        width: 60px;
        height: 60px;
    }

    &:hover {
        background-color: rgba(#000, 1);
    }

    img {
        display: block;
        max-width: 50px;
        margin-left: 5px;

        @include below(768px) {
            max-width: 25px;
        }
    }
}

.password-holder {
    width: 100%;
    height: 100%;
    background-color: $green;
    color: $white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    img {
        width: 100%;
        max-width: 250px;
    }

    h1 {
        font-size: 30px;
        margin: 10px 0 20px 0;
    }

    .post-password-form {
        width: 100%;
        max-width: 420px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        input[type="password"] {
            width: 100%;
            max-width: 250px;
            padding: 10px;
            border: 1px solid #fff;
        }

        button {
            width: 100%;
            max-width: 250px;
            padding: 10px;
            margin: 10px 0 0 0;
        }
    }
}

.post-password-form {
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
}